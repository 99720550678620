import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import Loader from '@/ui-kit/components/loader';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const LoaderFullscreen: FC<Props> = ({
  className,
}) => (
  <div {...{
    className: cx(styles.container, className),
  }}>
    <div {...{
      className: styles.inner,
    }}>
      <Loader {...{
        className: styles.loader,
      }} />
    </div>
  </div>
);

export default LoaderFullscreen;
